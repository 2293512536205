import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import "./CarouselBanner.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MissingContent from "../errorMessages/MissingContent";

// Define the types directly in this file
interface HeroImage {
  filename?: string;
  url: string;
}

interface VideoData {
  video_url: string;
  thumbnail_url?: string;
}

interface CarouselBannerProps {
  heroImages?: Array<HeroImage>;
  heroVideo?: VideoData | null;
  heroLink?: string;
  videoLink?: string;
  promoImages?: Array<HeroImage>;
  promoLink?: string;
  displayOption?: string;
}

const CarouselBanner = ({
                          heroImages = [],
                          heroVideo = null,
                          heroLink = '',
                          videoLink = '',
                          promoImages = [],
                          promoLink = '',
                          displayOption = 'carousel'
                        }: CarouselBannerProps) => {
  // Safely handle video content
  const videoItem = (heroVideo && heroVideo.video_url && (!displayOption || displayOption === 'video'))
    ? [{
      url: heroVideo.video_url,
      isVideo: true,
      link: videoLink || ''
    }]
    : [];

  // Safely handle image content based on display option
  const getImageItems = () => {
    if (displayOption === 'promo' && promoImages && promoImages.length > 0) {
      return promoImages.filter(Boolean).map((img: HeroImage) => ({
        url: img.url,
        isVideo: false,
        link: promoLink || ''
      }));
    }

    if (displayOption === 'carousel' || !displayOption ||
      (displayOption === 'video' && !heroVideo) ||
      (displayOption === 'promo' && (!promoImages || promoImages.length === 0))) {
      return (heroImages || []).filter(Boolean).map((img: HeroImage) => ({
        url: img.url,
        isVideo: false,
        link: heroLink || ''
      }));
    }

    return [];
  };

  const imageItems = getImageItems();

  // Combine items with null check
  const initialItems = displayOption === 'video' ? [...videoItem, ...imageItems] : [...imageItems, ...videoItem];

  interface CarouselItem {
    url: string;
    isVideo: boolean;
    link: string;
  }

  const [items, setItems] = useState<Array<CarouselItem>>(initialItems);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // Safely update items when props change
    const newVideoItem = (heroVideo?.video_url && (!displayOption || displayOption === 'video'))
      ? [{
        url: heroVideo.video_url,
        isVideo: true,
        link: videoLink || ''
      }]
      : [];

    const newImageItems = getImageItems();
    const newItems = displayOption === 'video' ? [...newVideoItem, ...newImageItems] : [...newImageItems, ...newVideoItem];
    setItems(newItems);

    // Reset current index if it's out of bounds
    if (currentIndex >= newItems.length) {
      setCurrentIndex(0);
    }
  }, [heroImages, heroVideo, heroLink, videoLink, promoImages, promoLink, displayOption]);

  // Rest of the component remains the same...
  const next = () => {
    if (items.length === 0) return;
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const previous = () => {
    if (items.length === 0) return;
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  // Optimized preloading to only load what's needed
  useEffect(() => {
    if (items.length === 0) return;

    // Only preload current and next items
    const itemsToPreload = [
      items[currentIndex],
      items[(currentIndex + 1) % items.length]
    ].filter(Boolean);

    itemsToPreload.forEach((item) => {
      if (!item?.url) return;

      if (item.isVideo) {
        // For videos, we'll rely on the preload attribute
      } else {
        const img = new Image();
        img.src = item.url;
      }
    });
  }, [items, currentIndex]);

  useEffect(() => {
    if (items.length === 0) return;

    if (displayOption === 'video' && items.length === 1 && items[0]?.isVideo) {
      return;
    }

    const interval = setInterval(() => {
      const currentItem = items[currentIndex];
      if (!currentItem) return;

      if (currentItem.isVideo && videoRef.current) {
        videoRef.current.play().catch(() => {
          console.warn('Video playback failed');
        });
      } else {
        next();
      }
    }, displayOption === 'promo' ? 2000 : 3000);

    return () => clearInterval(interval);
  }, [currentIndex, items, displayOption]);

  const handleVideoEnd = () => {
    if (items.length > 1 && displayOption !== 'video') {
      next();
    } else if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play().catch(() => {
        console.warn('Video replay failed');
      });
    }
  };

  const handleItemClick = (item: CarouselItem) => {
    if (item?.link) {
      window.location.href = item.link;
    }
  };

  const showNavigation = items.length > 1 &&
    !(displayOption === 'video' && items[0]?.isVideo) &&
    displayOption !== 'promo';

  return (
    <Box>
      {items.length === 0 ? (
        <MissingContent sectionName="Banner Carousel" />
      ) : (
        <Box
          sx={{
            width: "100%",
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
          }}
        >
          {items.map((item, index) => (
            item && (
              <Box
                key={index}
                className={`slide ${index === currentIndex ? "active" : ""}`}
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  cursor: item.link ? "pointer" : "default",
                  transition: displayOption === 'promo' ? 'opacity 0.3s ease-in-out' : 'opacity 0.5s ease-in-out',
                }}
                onClick={() => handleItemClick(item)}
              >
                {item.isVideo ? (
                  <video
                    ref={videoRef}
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      objectFit: "contain",
                    }}
                    onEnded={handleVideoEnd}
                    muted
                    autoPlay
                    playsInline
                    // Add poster image
                    poster={heroVideo?.thumbnail_url || ''}
                    // Only preload metadata initially
                    preload="metadata"
                  >
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      objectFit: "contain",
                    }}
                    src={item.url}
                    alt=""
                  />
                )}
              </Box>
            )
          ))}
          {showNavigation && (
            <>
              <ArrowBackIosIcon
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  left: "5%",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={previous}
              />
              <ArrowForwardIosIcon
                sx={{
                  position: "absolute",
                  bottom: "50%",
                  right: "5%",
                  zIndex: 1,
                  cursor: "pointer",
                }}
                onClick={next}
              />
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default CarouselBanner;